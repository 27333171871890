import { createRouter, createWebHistory } from "vue-router";
import { ROUTER_PATH, ROUTE_NAME, TYPE_USER } from "@/client/const";
import routerCustomer from "./routerCustomer";
import { guest } from "./middlewares";
import Cookies from 'js-cookie';

const Home = () => import("@/client/pages/Home");
const LoginCustomer = () => import("@/client/organisms/Customer/Login");
const ApplyCustomer = () => import("@/client/organisms/Customer/Apply");
const PolicyPage = () => import("@/client/views/Policy/PolicyPage.vue");
const RecoveryRequestCustomer = () => import("@/client/organisms/Customer/RecoveryRequest/RecoveryRequestCustomer.vue");
const Signup = () => import("@/client/organisms/Customer/Signup");
const SignupComplete = () => import("@/client/organisms/Customer/SignupComplete");
const VerificationCode = () => import("@/client/organisms/Customer/VerificationCode");
const SpecifiedCommercial = () => import('@/client/views/SpecifiedCommercial/SpecifiedCommercial.vue');


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: '【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description: 'カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      op_img: '',
      title_fb: '【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_fb: '',
      title_tw: '【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.LOGIN,
    component: LoginCustomer,
    name: ROUTE_NAME.CUSTOMER.LOGIN,
    meta: {
      middleware: [(context) => guest(context, TYPE_USER.USER)],
      title: 'ログイン|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングのログインページです。マイページで、予約内容の確認ができます。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      op_img: '',
      title_fb: 'ログイン|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'タイムシェアリングのログインページです。マイページで、予約内容の確認ができます。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      img_fb: '',
      title_tw: 'ログイン|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'タイムシェアリングのログインページです。マイページで、予約内容の確認ができます。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      img_tw: '',
    },
    beforeEnter: (to, from, next) => {
      to.meta.loginTo = from.meta.loginTo;
      if (to?.redirectedFrom?.path !== null) {
        if (to?.redirectedFrom?.path.includes("choose_payment")) {
          to.meta.redirectedFrom = to?.redirectedFrom?.path;
          to.meta.loginTo = 'choose_payment';
        }
      }
      next();
    },
  },
  {
    path: ROUTER_PATH.REGISTER,
    component: Signup,
    name: ROUTE_NAME.CUSTOMER.REGISTER,
    meta: {
      title: '新規会員登録|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングの新規会員登録ページです。会員登録すれば会場予約ができるようになります。公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペース・イベント会場を予約しましょう！無料で使い放題の設備・備品と、有料サービスでもっと便利に！',
      op_img: '',
      title_fb: '新規会員登録|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'タイムシェアリングの新規会員登録ページです。会員登録すれば会場予約ができるようになります。公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペースを予約しましょう！無料で使い放題の設備・備品と、有料サービスでもっと便利に！',
      img_fb: '',
      title_tw: '新規会員登録|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'タイムシェアリングの新規会員登録ページです。会員登録すれば会場予約ができるようになります。公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペースを予約しましょう！無料で使い放題の設備・備品と、有料サービスでもっと便利に！',
      img_tw: '',
    },
  },
  {
    path: ROUTER_PATH.SPECIFIED_COMMERCIAL,
    component: SpecifiedCommercial,
    meta: {
      title: '特定商取引法に基づく表記|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングの特定商取引法に基づく表記。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！用途別に、希望に合った時間で会場予約。',
      op_img: '',
      title_fb: '特定商取引法に基づく表記|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'タイムシェアリングの特定商取引法に基づく表記。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！用途別に、希望に合った時間で会場予約。',
      img_fb: '',
      title_tw: '特定商取引法に基づく表記|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'タイムシェアリングの特定商取引法に基づく表記。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペースの予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！用途別に、希望に合った時間で会場予約。',
      img_tw: '',
    },
  },
  {
    path: ROUTER_PATH.SIGNUP,
    component: Signup,
    name: ROUTE_NAME.CUSTOMER.SIGNUP,
    meta: {
      title: '新規会員登録',
      description: 'オシャレな貸し会議室、レンタルスペースをネットでカンタン予約できるサイト【TIME SHARING】の新規会員登録ページです。',
    },
  },
  {
    path: ROUTER_PATH.VERIFICATION_CODE,
    component: VerificationCode,
    name: ROUTE_NAME.CUSTOMER.VERIFICATION_CODE,
    meta: {
      title: '新規会員登録',
      description: 'オシャレな貸し会議室、レンタルスペースをネットでカンタン予約できるサイト【TIME SHARING】の新規会員登録ページです。',
    },
  },
  {
    path: ROUTER_PATH.SIGNUP_COMPLETE,
    component: SignupComplete,
    name: ROUTE_NAME.CUSTOMER.SIGNUP_COMPLETE,
    meta: {
      title: '新規会員登録',
      description: 'オシャレな貸し会議室、レンタルスペースをネットでカンタン予約できるサイト【TIME SHARING】の新規会員登録ページです。',
    },
  },
  {
    path: ROUTER_PATH.RECOVERY_REQUEST,
    component: RecoveryRequestCustomer,
    name: ROUTE_NAME.CUSTOMER.RECOVERY_REQUEST,
    meta: {
      title: 'パスワード再設定のリクエスト',
      description: 'TIME SHARINGはパーティー・女子会・会議・撮影・イベントなどで使える貸切できるレンタルスペースが簡単に予約できる検索サイトです。おしゃれなスペースから格安レンタル会場まで多数掲載。',
    },
  },
  {
    path: ROUTER_PATH.APPLY,
    component: ApplyCustomer,
  },
  {
    path: "/space/:id/:type",
    component: PolicyPage,
  },
  ...routerCustomer,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const middlewarePipeline = (context, middleware, index) => {
  const nextMiddleware = middleware[index];
  if (!nextMiddleware) {
    return context.next;
  }
  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);
    nextMiddleware({ ...context, next: nextPipeline });
  };
};

router.beforeEach((to, from, next) => {
    if (to.query.ref && to.query.ref.trim() !== "") {
        const myCookieValue = Cookies.get('affiliate');
        const currentDate = new Date();
        if (!myCookieValue) {
          var affiliate = {
          ref: to.query.ref,
          utm_source: to.query.utm_source,
          utm_medium: to.query.utm_medium,
          utm_campaign: to.query.utm_campaign,
          date: new Date(),
          dateExpire: new Date(currentDate.setDate(currentDate.getDate() + 30)),
        };
        Cookies.set('affiliate', JSON.stringify(affiliate), {
          expires: new Date(currentDate.setDate(currentDate.getDate() + 30)),
          path: '/'
        });
      } else {
        const cookieValue = JSON.parse(myCookieValue);
        if (cookieValue.ref !== to.query.ref) {
          const targetDate = new Date(cookieValue.dateExpire);
          const timeDifference = targetDate.getTime() - currentDate.getTime();
          const hoursDifference = Math.floor(timeDifference / (1000 , 60 , 60));
          var affiliateValue = {
            ref: to.query.ref,
            utm_source: to.query.utm_source,
            utm_medium: to.query.utm_medium,
            utm_campaign: to.query.utm_campaign,
            date: new Date(),
            dateExpire: new Date(currentDate.setDate(currentDate.getDate() + Math.floor(hoursDifference / 24) + 30)),
          };
          Cookies.remove("affiliate");
          Cookies.set('affiliate', JSON.stringify(affiliateValue), {
            expires: new Date(currentDate.setDate(currentDate.getDate() + Math.floor(hoursDifference / 24) + 30)),
            path: '/'
          });
        }
      }
    }
    const myCookieValue = Cookies.get('affiliate');
    if (myCookieValue) {
      const cookieValueJson = JSON.parse(myCookieValue);
      var affiliateStorage = {
        ref: cookieValueJson.ref,
        utm_source: cookieValueJson.utm_source,
        utm_medium: cookieValueJson.utm_medium,
        utm_campaign: cookieValueJson.utm_campaign,
      };
      if (to.query.ref === undefined && Object.keys(to.query).length > 0) {
        let newQuery = { ...to.query, ...affiliateStorage };
        return next({ path: to.path, query: newQuery });
      }
      if (Object.keys(to.query).length === 0) {
        return next();
      }
    }
    if (!to.meta.middleware) {
        return next();
      }
      const { middleware } = to.meta;
      const context = { to, from, next };
      return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
      });
  });
export default router;
