import api from "../http-request";
// import apiConstants from "../apiConstant";

// [GET METHOD]
export const getListDetailSpaceApi = async (id) =>
  api.get(`api/rental-spaces/detail/${id}/manage`);
export const getPriceSafetySpaceApi = async (id) =>
  api.get(`api/rental-spaces/price-safety/${id}`);
export const getPriceSafetySpaceListApi = async (id) =>
  api.get(`api/rental-spaces/safety-price-options/${id}`);
export const getFormSettingFromApi = async () =>
  api.get(`api/system-config/manage`);
export const getDetailSpaceTermOfUse = async (id) =>
  api.get(`api/rental-spaces/detail/${id}/term`);
export const getDetailSpaceIntroductionApi = async (id) =>
  api.get(`api/rental-spaces/detail/${id}/introduction`);
export const getOperatingHoursApi = async (id) =>
  api.get(`api/rental-spaces/detail/${id}/operatingHours`);

export const getSpaceInfoByDisplayIdApi = async (displayId) =>
  api.get(`api/rental-spaces/detail/${displayId}/displayId`);

export const getListSpaceCampaignApi = async () =>
  api.get(`api/ts-campaign/list`);
export const getListSpaceCampaignDetailApi = async (id) =>
  api.get(`api/ts-campaign/detail/${id}`);
export const getListIntervalByDayForSpaceId = async (id, day) =>
  api.get(`api/admin/reservations/plan/interval/${id}/${day}`);

export const handleBookingSpace = async (data) =>
  api.post(`api/customer/booking-space`, data);

export const completeBookingSpace = async (id, data) =>
  api.post(`/api/customer/booking-space/complete/${id}`, data);

export const getPlanIdForSpaceID = async (spaceId) =>
  api.get(`api/admin/rental-spaces/${spaceId}/list/rental-plan`);

export const getListIntervalByDateMin = async (spaceId, params) =>
  api.get(
    `api/admin/rental-spaces/${spaceId}/rental-plan/interval-in-this-day/minprice`,
    { params }
  );

export const getRentalPlanDeadline = async (spaceId) =>
  api.get(`api/admin/rental-spaces/${spaceId}/rental-plan/deadline`);
export const getListIntervalByDate = async (spaceId, planId, params) =>
  api.get(
    `api/admin/rental-spaces/${spaceId}/rental-plan/${planId}/interval-in-this-day/detail`,
    { params }
  );
export const getListPurposeOfUse = async () =>
  api.get(`api/get-list-purpose-of-use`);
export const checkCouponCodeDiscount = async (params) =>
  api.get(`api/coupon/check`, params);

export const getSpaceGeneralDetail = async (id) =>
  api.get(`/api/rental-spaces/detail/${id}/general`);
export const getReservationAcceptancePeriod = async (id) =>
  api.get(`/api/rental-spaces/${id}/reservation-acceptance-period`);

export const getCateringDetail = async () =>
  api.get(`/api/customer/catering/detail`);
export const getIntroductionRentalSpace = async (id, params) =>
  api.get(`/api/v2/rental-spaces/detail/${id}/introduction`, { params });
export const getAvailabilityTimeBooking = async (id, params) =>
  api.get(`/api/v2/rental-spaces/availability-time-booking/${id}`, { params });

export const getReservationPlan = async (id, dayIdent, params) =>
  api.get(`api/v2/rental-spaces/reservations/plan/interval/${id}/${dayIdent}`, {
    params,
  });

export const getAllPlanMaxMinTime = async (id) =>
  api.get(`/api/v2/rental-spaces/detail/${id}/rental-plan/interval`);


export const getOptionSpace = async (id) =>
  api.get(`api/v2/rental-spaces/list-option/${id}`);
export const getReservationTempApi = async (params) =>
  api.get(`api/v2/reservation/temp/list`, { params });
export const verifyReservationTempApi = async (params) =>
    api.get(`/api/v2/reservation/temp/verify`, { params });
export const deleteReservationTempApi = async (id) =>
  api.delete(`/api/v2/reservation/temp/delete/${id}`);
export const updateOrCreateReservationTempApi = async (params) =>
  api.post(`/api/v2/reservation/temp/create-update`, params);

export const editReservationTempApi = async (id) =>
  api.get(`api/v2/reservation/booking/${id}/edit`);
export const detailReservationTempApi = async (id) =>
  api.get(`/api/v2/reservation/temp/detail/${id}`);

export const handleBookingConfirmation = async (data) =>
  api.post(`api/v2/reservation/booking-space`, data);

export const getPaymentMethodsApi = async (params) =>
  api.get(`api/v2/reservation/temp/payments`, { params });

export const getReservationCheckExists = async (params) =>
  api.get(`api/v2/reservation/check-exists`, { params });

export const getPaymentLimitOfReceipt = async (params) =>
  api.get(`api/v2/reservation/check-limit-per-month`, { params });

export const getSpaceInfoBySpaceIdApi = async (spaceId) =>
  api.get(`api/rental-spaces/detail-info/${spaceId}/id`);

export const getBonusOfSpace = async (spaceId) =>
  api.get(`api/bonus/space/${spaceId}`);
export const getListFileBySpaceIdApi = async (spaceId) =>
  api.get(`api/upload-file/list-for-space-fe/${spaceId}`);
export const getSafetyListOverviewApi = async (safety_price_id) =>
  api.get(`api/safety-list-overview?safety_price_id=${safety_price_id}`);
