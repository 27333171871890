<template>
  <div class="wrapper">
    <div class="common-call-wrap " :class="[ isModelShow ? '' : 'd-none']">
        <button class="contact-note-section" @click="openInfoModal">
            <img class="cross" :src="closeBtn" alt="" srcset="" />
        </button>
      <div class="title">
        使いたいスペースが空いてない！<br />
        そんな時はご連絡ください！
        <div class="time-support">受付時間 10:00 〜 18:00</div>
      </div>
      <div class="call-wrap">
        <div class="call-pc">
          <p class="call-title">予約前の方</p>
          <div class="number">03-6800-8931</div>
        </div>
        <div class="call-pc">
          <p class="call-title">予約後の方</p>
          <div class="number">03-6800-8932</div>
        </div>
      </div>
    </div>
    <div class="message-round-btn-section">
      <button
        class="message-btn-image"
        :class="[isModelShow ? 'd-none' : '']"
        @click="openInfoModal"
      >
        <img class="messageImg" :src="messageBtn" alt="" srcset="" />
        <span>電話で相談する</span>
      </button>
    </div>
    <div
      class="contact-mobile d-none d-sm-none"
      :class="{ 'd-block': isShowMobileContact }"
      v-scroll="onScroll"
    >
      <div class="button-container">
        <div class="dialog-button" @click="isShowDialog = true">
          電話相談・問い合わせ
        </div>
      </div>
    </div>
    <InquiriesDialog
      :showDialog="isShowDialog"
      @closeDialog="isShowDialog = false"
    />
  </div>
</template>
<script setup>
import InquiriesDialog from "@/client/organisms/LayoutHome/ContactInfo/InquiriesDialog.vue";
import { ref } from "vue";
import closeBtn from "@/admin/assets/images/close.png";
import messageBtn from "@/admin/assets/images/information-icon.png";

const isShowMobileContact = ref(false);
const isShowDialog = ref(false);
const isModelShow = ref(true);
const onScroll = (e) => {
  const scrollDistance = e.target
    .getElementsByClassName("v-main")[0]
    .getBoundingClientRect().top;
  if (scrollDistance < 0) {
    isShowMobileContact.value = true;
  } else {
    isShowMobileContact.value = false;
  }
};
const openInfoModal = () => {
  isModelShow.value = !isModelShow.value;
};
</script>
<style lang="scss" scoped>
.wrapper {
  z-index: 998;
}
.common-call-wrap {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  z-index: 9999;
  border: solid 2.5px #297fca;
  text-align: center;
  .contact-note-section {
    width: 30px;
    position: absolute;
    padding: 10px;
    border-radius: 100%;
    right: -7px;
    top: -6px;
  }
  p {
    padding: 3px 0;
    font-size: 13px;
    background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
    border-radius: 5px;
  }
  .title {
    font-size: 13px;
    font-weight: bold;
    background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
    padding: 13px 10px;
    color: #fff;
    border-radius: 5px 5px 0 0;

    .time-support {
      font-weight: normal;
      font-size: 13px;
    }
  }
  .call-pc {
    color: #ffffff;
    text-align: center;
    margin-bottom: 5px;
    .number {
      font-size: 18px;
      margin-top: 3px;
      font-weight: bold;
      color: #0062c5;
    }
  }
  .call-wrap {
    width: 80%;
    margin: 10px auto 0;
  }
}
.message-round-btn-section {
  .message-btn-image {
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #007ec5;
    padding: 10px 10px;
    background: #fff;
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 10px;
    width: 60px;
    span {
      color: #0062c5;
      text-align: center;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

.wrapper {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
}
.contact-mobile {
  color: #fff;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1;
  margin-top: -82px;
  .button-container {
    width: 95%;
    max-width: 100%;
    margin: auto;
    padding: 20px 0px 5px 0px;
    .dialog-button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 5px;
      padding: 10px 5px;
      font-size: 18px;
      display: block;
      text-align: center;
      background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
      box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.6);
    }
  }
}
.contact-inquiries {
  background: #fff;
  padding: 35px 0 20px;
  position: relative;
  border-radius: 4px;
  max-width: 373px;
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 0%;
    background: rgba(0, 0, 0, 0.4);
    &::before {
      font-weight: bold;
      content: "x";
      color: #fff;
      font-size: 20px;
    }
  }
  .inquiries-button-wrap {
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    .inquiries,
    .tel {
      width: 80%;
      margin: 5px auto;
      color: #fff;
      border-radius: 4px;
      padding: 10px;
      display: block;
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(86, 216, 157, 0.8) 100%
      );
      text-decoration: none;
      text-align: center;
      font-size: 16px;
    }
    .tel {
      position: relative;
      text-align: left;
      font-weight: bold;
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(10, 80, 201, 0.8) 100%
      );
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        content: "";
        background-image: linear-gradient(to left, #30cfd0 0%, #330867 100%);
      }
    }
  }
}
.my-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
