import api from "../http-request";
// import apiConstants from "../apiConstant";

// [GET METHOD]

export const getListLocationApi = async () =>
  api.get(`api/admin/location/list`);
export const getListCategorySpaceApi = async () =>
  api.get(`api/category-spaces/list`);
export const getListRentalSpaceApi = async (params) =>
  api.get(`api/rental-spaces/list`,{ params });
export const getListRecommendedSpaceApi = async (params) =>
  api.get(`api/rental-spaces/list-recommended`,{ params });
export const getListRoleBannerApi = async (params) =>
  api.get(`/api/roll-banner/list`, { params });
  export const getListFaqApi = async (params) =>
  api.get(`api/sys-faq/list`, { params });
export const getListNewsApi = async (params) =>
  api.get(`api/news/list`, { params });
export const getListNewsDetailApi = async (id) =>
  api.get(`api/news/detail/${id}`);
export const getListTopVisualApi = async () =>
api.get(`api/user/banner/list-top-visual`);
export const getListCampaignBannerApi = async () =>
api.get(`api/user/banner/list-campaign-banner`);
export const getUserGuideTopSettingApi = async () =>
  api.get(`api/user-guide/top/setting`);
export const getUserGuideFaqsApi = async (params) =>
  api.get(`api/user-guide/faq/list`, { params });
export const getUserGuideFaqBySlugApi = async (slug) =>
  api.get(`api/user-guide/faq/detail/${slug}`);
export const getListGroupAreaApi = async () =>
  api.get(`api/group-area/list`);
// POST METHOD
export const createTourApi = async (id, data) =>
  api.post(`api/space/tours/${id}`, data);
export const updateIsReadInquiry = async (id, data) =>
  api.post(`api/customer/inquiry/update/read/${id}`, data);
export const getListUserGuide = async () =>
    api.get(`api/admin/list-ts-user-guide`);
export const getDetailAffiliateByCode = async (params) =>
    api.get(`api/affiliate-by-code`, {params});
export const checkPreviewPassword = async (id, data) =>
    api.post(`api/rental-spaces/${id}/check-preview-password`,data);
export const GetCalendarSpaceApi = async (params) =>
    api.get(`/api/rental-spaces/list-calendar`, {params});
