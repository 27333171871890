<template>
    <div class="header">
        <div v-if="notificationList.length">
            <Carousel :autoplay="5000" :wrap-around="true">
                <Slide v-for="(item, index) in notificationList" :key="index">
                    <div class="carousel__item" @click="handleShowModalNotification(item.id)"
                    :style="{ background: item?.title_background, color: item?.text_color }">
                        {{ item.title }}
                    </div>
                </Slide>
            </Carousel>
        </div>
        <div class="header-content justify-space-between d-flex">
            <div class="header-logo d-flex align-center">
                <div class="logo-timeshare">
                    <router-link to="/">
                        <img
                            :src="time_sharing_logo"
                            alt="【公式】貸し会議室・レンタルスペースならタイムシェアリング"
                            class="imglogo pc"
                            width="285"
                            height="57"
                        />
                        <img
                            :src="time_sharing_logo_sp"
                            alt="【公式】貸し会議室・レンタルスペースならタイムシェアリング"
                            class="sp"
                            width="110"
                            height="39.03"
                        />
                    </router-link>
                </div>
                <div class="logo-sub translate-box d-flex">
                    <span @click="handleLogo">
                        <img
                            :src="bestrateicon"
                            alt="BEST RATEロゴ"
                            :style="bestRateStyle"
                            class="imglogo"
                        />
                    </span>
                    <img
                        class="img-transiton"
                        :src="prime_tokyo"
                        alt="JPX東証プライムロゴ"
                        :style="primeTokyoStyle"
                    />
                </div>
                <div class="pl-md-4 text-left">
                    <p class="logo-desc logo-description d-none d-md-block">
                        エリア・用途・人数から探せる<br class="d-none d-md-block d-lg-none">スペース検索予約サイト
                    </p>
                    <div class="cursor-pointer" @click="handelShowModal">
                        <div class="text-[#2877bc] text-[7px] lg:text-[12px] pl-1 md:pl-0">
                            当社は東証プライム上場の<br class="lg:hidden" />株式会社ビジョングループ<br class="lg:hidden" />会社です。
                        </div>
                    </div>
                </div>
            </div>
            <HeaderMenu
                class="header-block"
                :dialogApply="dialogApply123"
                @closeModalApply="closeModalApply"
            />
            <div
                class="application-button d-flex flex-column justify-center align-center d-md-none"
                @click="goToApply"
            >
                <img class="hidden-sp" style="width: 21px;" :src="icn_select_date_time_sp" alt="申込検索ボタンアイコン">
                <div class="title">会場検索<br>お申込み</div>
            </div>
            <div class="app-drawer-button d-md-none">
                <HamburgerButton :openState="drawer" @toggle="drawer = $event" />
            </div>
            <v-navigation-drawer
                class="nav-screen"
                v-model="drawer"
                :width="width + 20"
                location="right"
                temporary
            >
                <DrawerNav />
            </v-navigation-drawer>
        </div>
        <div class="header-menu d-none d-md-block">
            <ul class="shortcut d-flex align-center">
                <li v-for="(menuItem, index) in listMenuHeader" :key="index">
                    <a v-if="menuItem.id === 8"
                        :href="menuItem.link" target="_blank">
                        {{ menuItem.title }}
                    </a>
                    <a v-else-if="shouldUseExternalLink(menuItem)"
                        :href="menuItem.link">
                        {{ menuItem.title }}
                    </a>
                    <router-link v-else
                        :to="menuItem.link">
                        {{ menuItem.title }}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>

  <!-- company dialog -->
    <v-row justify="center" align="center">
        <v-dialog
            class="apply-action__box"
            v-model="dialogCompany"
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
            max-width="89%"
        >
            <CompanyDialogCard
                class="company-block"
                :isShowdialogCompany="dialogCompany"
                @closeDialogCompany="dialogCompany = false"
            />
        </v-dialog>
    </v-row>

    <!-- bestrateicon -->
    <div>
        <v-row justify="center" align="center">
            <v-dialog
                class="apply-action__box"
                v-model="dialogBestrate"
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
                max-width="1480px"
            >
                <BestrateDialogCard
                    class="company-block"
                    :isShowdialogBestrate="dialogBestrate"
                    @closeDialogBestrate="dialogBestrate = false"
                />
            </v-dialog>
        </v-row>
    </div>

    <ModalNotificationDetail
        v-if="showModalNotificationDetail"
        @onClose="showModalNotificationDetail = false"
        :notificationDetail="notificationDetail"
    />
</template>

<script>
import DrawerNav from "@/client/components/DrawerNav/DrawerNav.vue";
import HeaderMenu from "@/client/molecules/LayoutHome/HeaderMenu.vue";
import HamburgerButton from "@/client/atoms/HamburgerButton.vue";
import CompanyDialogCard from "@/client/organisms/LayoutHome/Dialog/CompanyDialogCard.vue";
import BestrateDialogCard from "@/client/organisms/LayoutHome/Dialog/BestrateDialogCard.vue";
import time_sharing_logo from "@/client/assets/images/time_sharing_logo.png";
import time_sharing_logo_sp from "@/client/assets/images/timesharingSP.png";
import icn_select_date_time_sp from "@/client/assets/images/icn_select-date-time-sp-white.png";
import bestrateicon from "@/client/assets/images/bestrateIcon.png";
import prime_tokyo from "@/client/assets/images/jpx_prime_tokyo.png";
import {ref, computed, reactive, onMounted, inject} from "vue";
import { useDisplay } from "vuetify";
import { ROUTER_PATH } from "@/client/const";
import { Carousel, Slide } from 'vue3-carousel'
import "vue3-carousel/dist/carousel.css";
import ModalNotificationDetail from "@/client/components/Modal/ModalNotificationDetail.vue";
import {
  getListNotification,
  getDetailNotification,
} from "@/client/api";
import useStore from "@/client/store/Customer";
import { useI18n } from "vue-i18n";

export default {
    name: "AppHeader",
    components: {
        HeaderMenu,
        HamburgerButton,
        DrawerNav,
        CompanyDialogCard,
        BestrateDialogCard,
        Carousel,
        Slide,
        ModalNotificationDetail,
    },

    setup() {
        const { name, width } = useDisplay();
        const drawer = ref(false);
        const dialogCompany = ref(false);
        const dialogBestrate = ref(false);
        const dialogApply123 = ref(false);
        const showModalNotificationDetail = ref(false);
        const notificationList = ref([]);
        const uStore = useStore();
        const toast = inject("$toast");
        const notificationDetail = ref({});
        const { t } = useI18n();

        onMounted(async () => {
            const { data } = await getListNotification();
            if(data && data?.length) {
                notificationList.value = data;
            }
        });

        const handelShowModal = () => {
            dialogCompany.value = true;
        };
        const subLogoStyle = {
            bestRate: {
                sm: "width: 55px; height: 55px",
                xs: "width: 40px; height: 40px",
            },
            primeTokyo: {
                sm: "width: 41.07px; height: 53px;",
                xs: "width: 30.22px; height: 39px;",
            },
        };
        const bestRateStyle = computed(() =>
            name.value == "xs" ? subLogoStyle.bestRate.xs : subLogoStyle.bestRate.sm
        );
        const primeTokyoStyle = computed(() =>
            name.value == "xs"
                ? subLogoStyle.primeTokyo.xs
                : subLogoStyle.primeTokyo.sm
        );
        const handleLogo = () => {
            dialogBestrate.value = true;
        };
        const goToApply = () => {
            dialogApply123.value = true;
        };
        const closeModalApply = () => {
            dialogApply123.value = false;
            uStore.isShowModalSearch = false;
        };
        const listMenuHeader = reactive([
            {
                id: 1,
                title: "エリアから探す",
                link: `${ROUTER_PATH.CONTENT_LOCATION_HEADER}`,
            },
            {
                id: 2,
                title: "利用用途から探す",
                link: `${ROUTER_PATH.CONTENT_CASE}`,
            },
            {
                id: 8,
                title: "パーティー会場",
                link: `${ROUTER_PATH.FOR_PARTY}`,
            },
            {
                id: 3,
                title: "初めての方",
                link: `${ROUTER_PATH.LP_HOW_TO_USE}`,
            },
            {
                id: 4,
                title: "ケータリング",
                link: `${ROUTER_PATH.LP_CATERING}`,
            },
            {
                id: 5,
                title: "スペース安心補償",
                link: `${ROUTER_PATH.LP_SPACE_SAFETY}`,
            },
            {
                id: 6,
                title: "法人利用",
                link: `${ROUTER_PATH.LP_CORPORATE_USE}`,
            },
            {
                id: 7,
                title: "よくある質問",
                link: `${ROUTER_PATH.USER_GUIDE.FAQ}`,
            },
        ]);
        const handleShowModalNotification = async (id) => {
            try {
                uStore.isLoading = true;
                const res = await getDetailNotification(id);
                if (res.data) {
                    notificationDetail.value = res.data;
                    uStore.isLoading = true;
                    showModalNotificationDetail.value = true;
                }
            } catch (errors) {
                    const error = errors.message || t("common.has_error");
                    toast.error(error);
            } finally {
                    uStore.isLoading = false;
            }
        }

        return {
            time_sharing_logo,
            time_sharing_logo_sp,
            bestrateicon,
            prime_tokyo,
            bestRateStyle,
            primeTokyoStyle,
            drawer,
            width,
            handelShowModal,
            dialogCompany,
            handleLogo,
            dialogBestrate,
            goToApply,
            dialogApply123,
            closeModalApply,
            listMenuHeader,
            icn_select_date_time_sp,
            showModalNotificationDetail,
            handleShowModalNotification,
            notificationList,
            notificationDetail
        };
    },
    methods: {
        shouldUseExternalLink(menuItem) {
            return menuItem.title === 'ケータリング' || menuItem.title === 'スペース安心補償';
        }
    },
};
</script>

<style lang="scss" scoped>
@import "./header.scss";

.apply-action__box {
  background-color: rgba(0, 0, 0, 0.7);
}
.select-col {
  padding: 0;
}
div.v-input__details {
  display: none !important;
}
.select-col[data-v-0affa92c] {
  padding: 0;
}
.v-select .v-field.v-field {
  cursor: pointer;
  background-color: white;
}
.v-row {
  justify-content: center;
  margin: 0;
}
.theme--light.v-list-item:hover::before {
  opacity: 0.64;
}
::v-deep .v-input__control {
  display: flex;
  grid-area: control;
  background-color: white;
}
.v-sheet-box {
  transform: translateY(12px);
}
.select-location {
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 10px 50px 10px 10px;
  cursor: pointer;
  text-indent: 0.01px;
  background: #ffffff;
  border: solid 1px #dcdddd;
  outline: none;
  color: #000;
      &::placeholder {
        color: #B4B4B4;
      }
}
@media screen and (max-width: 1280px) {
  .v-dialog--scrollable .v-overlay__content>.v-card {
    overflow: scroll;
  }
  .hidden-sp {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .search-apply {
    margin: 0;
    transform: translateY(36px);
  }
  .hidden-sp {
    display: none;
  }
}
.carousel__item {
    background: red;
    width: 100%;
    font-size: 16px;
    padding: 2px;
}
.carousel__item {
    background: red;
    width: 100%;
    font-size: 16px;
    padding: 2px;
}
</style>
